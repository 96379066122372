.readme {
  $linkColor: darkcyan;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 26px;
    border-bottom: 1px solid rgba(30, 41, 59, 0.4);
    margin-bottom: 12px;
    margin-top: 16px;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }
  
  h6 {
    font-size: 18px;
  }

  a {
    color: $linkColor;
  }
}
