@import url("https://fonts.googleapis.com/css2?family=Recursive:wght@300;400;500;600;700;800;900;1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Recursive", sans-serif;
  overflow-y: scroll;
}
.data-tippy-root {
  font-weight: 600;
}
